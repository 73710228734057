<template>
  <div class="page flex-col">
    <div class="mainbox">
    <div class="section_1 flex-col">
      <div class="header justify-between">
        <div class="logo">
          <img src="./assets/img/Frame(4).png" alt="">
          <span>ANY CONSTRUCTION</span>
        </div>
       
          <div class="nav">
            <span class="" @click="goPage('home')">หน้าแรก</span>
            <span class="active" @click="goPage('about')">เกี่ยวกับเรา</span>
            <span class="text_4" @click="goPage('services')">บริการของเรา</span>
            <span class="text_5" @click="goPage('contact')">ติดต่อเรา</span>
          </div>
          <div class="section_1_text">
            <div class="big">
              เกี่ยวกับเรา
            </div>
          </div>
   
      </div>
    </div>
    <div class="section_2 ">
      <div class="itemstep">
        <div class="num">
          20+
        </div>
        <div class="stepmm">
          ทีมนักพัฒนา
        </div>
      </div>
      <div class="itemstep">
        <div class="num">
          3000+
        </div>
        <div class="stepmm">
          ให้บริการด้านวิทยาศาสตร์และเทคนิค
        </div>
      </div>
      <div class="itemstep">
        <div class="num">
          200+
        </div>
        <div class="stepmm">
          บริการด้านวิทยาศาสตร์และเทคนิค
        </div>
      </div>
    </div>
    <div class="section_3">
      <div class="linebox"></div>
      <div class="completetitle">
        การสำรวจทางวิทยาศาสตร์ สร้างความเป็นเลิศ
      </div>
      <div class="completetitlemm">
        บริษัทของเราใช้การสำรวจทางวิทยาศาสตร์เป็นหลัก และสร้างความสำเร็จทางวิทยาศาสตร์และเทคโนโลยีที่โดดเด่นผ่านการวิจัยและพัฒนาอย่างเป็นระบบ เรามีทีมวิจัยทางวิทยาศาสตร์ที่กระตือรือร้นซึ่งมุ่งเน้นประเด็นทางวิทยาศาสตร์ในสาขาต่างๆ และแสวงหาความก้าวหน้าทางนวัตกรรม เป้าหมายของเราคือการแปลงผลการวิจัยทางวิทยาศาสตร์ไปสู่การใช้งานจริง มอบโซลูชันทางเทคนิคที่มีประสิทธิภาพและเชื่อถือได้แก่ลูกค้า ช่วยให้พวกเขาได้เปรียบในการแข่งขันในระดับแนวหน้าของวิทยาศาสตร์และเทคโนโลยี และสร้างอนาคตที่โดดเด่นยิ่งขึ้น
      </div>
      <div class="boxcom">
        <div class="boxcomitem">
          <img src="./assets/img/Rectangle 39937.png" alt="">
          <div class="boxcomitemtitle">
            ขับเคลื่อนด้วยนวัตกรรม สู่อนาคต
          </div>
          <div class="boxcomitemtmmi">
            เราเชื่อมั่นว่านวัตกรรมเป็นพลังขับเคลื่อนหลักในการพัฒนา เราจะสร้างมูลค่าที่มากขึ้นให้กับลูกค้าของเราและร่วมกันบรรลุอนาคตที่ดีกว่า
          </div>
        </div>

        <div class="boxcomitem">
          <img src="./assets/img/Rectangle 39939.png" alt="">
          <div class="boxcomitemtitle">
            “เทคโนโลยีเปลี่ยนชีวิต นวัตกรรมนำไปสู่อนาคต
          </div>
          <div class="boxcomitemtmmi">
            บริษัทของเรามุ่งมั่นที่จะเปลี่ยนแปลงวิถีชีวิตของผู้คนและเป็นผู้นำการพัฒนาและความก้าวหน้าของสังคมผ่านเทคโนโลยีและนวัตกรรม
          </div>
        </div>

        <div class="boxcomitem">
          <img src="./assets/img/Rectangle 39938.png" alt="">
          <div class="boxcomitemtitle">
            “ร่วมมือกันสร้างความสดใส
          </div>
          <div class="boxcomitemtmmi">
            เรายินดีที่จะทำงานร่วมกับลูกค้าของเราเพื่อเผชิญกับความท้าทายและสร้างความสำเร็จที่ยอดเยี่ยมร่วมกัน
          </div>
        </div>
      </div>
    </div>
    <div class="section_4">
      <div class="engaleft">
        <img src="./assets/img/Rectangle 39961.png" alt="">
      </div>
      <div class="engaright">
        <div class="linebox"></div>
        <div class="engarighttitle">
          เทคโนโลยีล้ำสมัยขับเคลื่อนอนาคตอันชาญฉลาด
        </div>
        <div class="engarighttitlemm">
          ในสาขาวิทยาศาสตร์และเทคโนโลยีที่มีการพัฒนาอยู่ตลอดเวลา เราอยู่ในแถวหน้าเสมอในการสำรวจแนวโน้มและการประยุกต์ใช้เทคโนโลยีล่าสุด ภารกิจของเราคือการเติมความฉลาดและความมีชีวิตชีวาให้กับธุรกิจของลูกค้าของเราผ่านเทคโนโลยีชั้นนำและโซลูชั่นที่เป็นนวัตกรรม ไม่ว่าจะเป็นปัญญาประดิษฐ์ การวิเคราะห์ข้อมูล หรือระบบอัตโนมัติ เรามุ่งมั่นที่จะนำเทคโนโลยีล้ำสมัยมาประยุกต์ใช้กับธุรกิจจริง ส่งเสริมการพัฒนาปัญญา และช่วยให้ลูกค้าก้าวนำหน้าคลื่นเทคโนโลยีในอนาคตหนึ่งก้าว
        </div>
        <div class="engarighttitlemmid">
          วิทยาศาสตร์ที่แม่นยำ โซลูชันที่ปรับแต่งเฉพาะบุคคล
        </div>
        <div class="engarighttitlemm">
          บริษัทของเรามุ่งเน้นไปที่การประยุกต์ใช้วิทยาศาสตร์และเทคโนโลยีที่มีความแม่นยำ และมุ่งมั่นที่จะมอบโซลูชั่นคุณภาพสูงสุดให้กับลูกค้า ไม่ว่าจะเป็นการวิจัยทางวิทยาศาสตร์ วิศวกรรม หรือการพัฒนาเทคโนโลยี ทีมผู้เชี่ยวชาญของเรารับมือกับความท้าทายต่างๆ ด้วยทัศนคติที่เข้มงวดและทักษะที่เป็นเลิศ เราเชื่อว่าด้วยการวิจัยทางวิทยาศาสตร์ที่ซับซ้อนและการวิเคราะห์ทางเทคนิคเชิงลึก เราสามารถปรับแต่งโซลูชั่นที่มีประสิทธิภาพและคาดการณ์ล่วงหน้าที่สุดสำหรับลูกค้าของเรา เพื่อช่วยให้พวกเขาโดดเด่นในการแข่งขันในตลาดที่รุนแรง
        </div>
      </div>
    </div>
    <div class="section_5">
      <div class="linebox"></div>
      <div class="placetitle">
        เลือกเรา ก้าวไปพร้อมกับเทคโนโลยี
      </div>
      <div class="placemin">
        วิทยาศาสตร์และเทคโนโลยีอยู่ใกล้แค่ปลายนิ้ว เชี่ยวชาญด้านวิทยาศาสตร์และเทคโนโลยีขั้นสูงไปกับเรา
      </div>
      <div class="placebox">
        <div class="placeboxitem">
          <div class="placeboxleft">
            <img src="./assets/img/Frame.png" alt="">
          </div>
          <div class="placeboxright">
            <div class="placeboxrighttt">
              ค่านิยมหลัก
            </div>
            <div class="placeboxrightm">
              เรายึดมั่นในค่านิยมหลักของ "นวัตกรรม ความเป็นเลิศ และความซื่อสัตย์" และช่วยเหลือลูกค้าในการแก้ปัญหาในทางปฏิบัติและเพิ่มขีดความสามารถในการแข่งขันผ่านนวัตกรรมทางเทคโนโลยีที่ต่อเนื่องและบริการที่มีคุณภาพ ในขณะเดียวกัน เรายังให้ความสำคัญกับการพัฒนาที่ยั่งยืนและความรับผิดชอบต่อสังคม และมุ่งมั่นที่จะสร้างคุณค่าที่มากขึ้นให้กับสังคม
            </div>
          </div>
         
        </div>
        <div class="placeboxitem">
          <div class="placeboxleft">
            <img src="./assets/img/Frame(1).png" alt="">
          </div>
          <div class="placeboxright">
            <div class="placeboxrighttt">
              เส้นทางการพัฒนา
            </div>
            <div class="placeboxrightm">
              เรายึดมั่นในนวัตกรรมทางเทคโนโลยีและบริการคุณภาพสูงเสมอ โดยยึดการสำรวจทางวิทยาศาสตร์เป็นแกนหลัก สร้างความสำเร็จทางวิทยาศาสตร์และเทคโนโลยีที่โดดเด่นผ่านการวิจัยและพัฒนาอย่างเป็นระบบ และค่อยๆ กลายเป็นผู้นำในอุตสาหกรรม
            </div>
          </div>
         
        </div>
        <div class="placeboxitem">
          <div class="placeboxleft">
            <img src="./assets/img/Frame(2).png" alt="">
          </div>
          <div class="placeboxright">
            <div class="placeboxrighttt">
              เป้าหมายสูงสุด
            </div>
            <div class="placeboxrightm">
              เป้าหมายคือการแปลงผลการวิจัยทางวิทยาศาสตร์ไปสู่การใช้งานจริง มอบโซลูชันทางเทคนิคที่มีประสิทธิภาพและเชื่อถือได้แก่ลูกค้า ช่วยให้พวกเขาได้เปรียบในการแข่งขันในระดับแนวหน้าของวิทยาศาสตร์และเทคโนโลยี และสร้างอนาคตที่โดดเด่นยิ่งขึ้น
            </div>
          </div>
         
        </div>
        <div class="placeboxitem">
          <div class="placeboxleft">
            <img src="./assets/img/Frame(3).png" alt="">
          </div>
          <div class="placeboxright">
            <div class="placeboxrighttt">
              ประสบการณ์โครงการมากมายและการสนับสนุนอย่างมืออาชีพ
            </div>
            <div class="placeboxrightm">
              มันแสดงให้เห็นถึงประสบการณ์โครงการอันยาวนานของเราที่สั่งสมมาจากหลายอุตสาหกรรม และสามารถจัดหาโซลูชั่นระดับมืออาชีพสำหรับกลุ่มต่างๆ นอกจากนี้ยังแสดงให้เห็นว่าเรามีความสามารถในการตอบสนองต่อความต้องการที่ซับซ้อนต่างๆ และสามารถให้บริการที่มีคุณภาพสูงแก่ลูกค้าได้
            </div>
          </div>
         
        </div>
      </div>
    </div>
    </div>
    <div class="bootomcard">
      <div class="bootomtop">
        <div class="bottomtitle">
          ANY CONSTRUCTION
        </div>
        <div class="bottomtitlem">
          ติดต่อเราเพื่อความร่วมมือแบบ win-win
        </div>
        <div class="bootominfos">
          <div class="bootominfo">
            <img src="./assets/img/Group 14031(1).png" alt="">
            info@subco.com
          </div>
          <div class="bootominfo">
            <img src="./assets/img/Group 14031.png" alt="">
            (917) 814 - 3527
          </div>
          <div class="bootominfo">
            <img src="./assets/img/adress.png" alt="">
            Corumbá 562-516, Valle del Tepeyac, Gustavo A. Madero, 07740 Ciudad de México,CDMX,México
          </div>
        </div>
      </div>
      <div class="copyright">
        © 2024 Relume. All rights reserved.
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  methods: {
    goPage(path) {
      this.$router.push({ name: path })
    }
  }
};
</script>
<style scoped lang="less" src="./assets/index.response.less" />